import React from "react"
import Layout from "../components/layout"
import Nav from 'react-bootstrap/Nav'
import Carousel from 'react-bootstrap/Carousel'
import * as cn from "classnames"
import {Link} from "gatsby";
import {HelmetDatoCms} from "gatsby-source-datocms";

export default class Product extends React.Component {
    state = {
        activeTab: "preview"
    }

    render() {
        const p = this.props, { } = p
        const s = this.state, { } = s
        const activeTab = this.state.activeTab
        const product = p.data.product
        let carouselType
        if (product.gallery) {
            carouselType = product.gallery.length
        }
        else {
            carouselType = null
        }

        return (
            <Layout className="solid">
                <HelmetDatoCms seo={product.seoMetaTags} />
                <div className="breadcrumb">
                    <span>Produkty</span>
                    <a href={`/category/${product.category.slug}`} title={product.category.title}>{product.category.title}</a>
                    {product.subCategory && <a href={`/category/${product.category.slug}/${product.subCategory.slug}`} title={product.subCategory.title}>{product.subCategory.title}</a>}
                </div>
                <div className="container">
                    <div className="preview">
                        {carouselType && carouselType > 0 &&
                            <Carousel className={cn(carouselType === 1 && "single")}>
                                {product.gallery.map(({ fluid: image }) => (
                                    <Carousel.Item>
                                        {image && <img src={image.src} alt="" />}
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        }
                        <div className="preview__content">
                            <div className="preview__left">
                                <h3>{product.title}</h3>
                                <p dangerouslySetInnerHTML={{
                                    __html: product.description,
                                }}
                                />
                            </div>
                            {product.advantages &&
                                <div className="preview__right">
                                    <h3>Zalety</h3>
                                    <div dangerouslySetInnerHTML={{
                                        __html: product.advantages,
                                    }}
                                    />
                                </div>
                            }
                        </div>
                        <Nav activeKey={activeTab} onSelect={(selectedKey) => this.setState({activeTab: selectedKey})}>
                            <Nav.Item>
                                <Nav.Link eventKey="preview">Podgląd</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="options">Dodatkowe Opcje</Nav.Link>
                            </Nav.Item>
                            {product.pliki.length > 0 &&
                                <Nav.Item>
                                    <Nav.Link eventKey="download">Do pobrania</Nav.Link>
                                </Nav.Item>
                            }
                        </Nav>
                        {activeTab === "preview" &&
                            <div className="preview__details">
                                <h4>Dane techniczne</h4>
                                <table className="table">
                                    <tbody>
                                    {product.cisnienie &&
                                        <tr>
                                            <td>Ciśnienie [m]</td>
                                            <td>{product.cisnienie}</td>
                                        </tr>
                                    }
                                    {product.wydajnosc &&
                                        <tr>
                                            <td>Wydajność [m<span className="text-top">3</span>/h]</td>
                                            <td>{product.wydajnosc}</td>
                                        </tr>
                                    }
                                    {product.temperatura &&
                                        <tr>
                                            <td>Temperatura [&deg;C]</td>
                                            <td>{product.temperatura}</td>
                                        </tr>
                                    }
                                    {product.moc &&
                                        <tr>
                                            <td>Moc [kW]</td>
                                            <td>{product.moc}</td>
                                        </tr>
                                    }
                                    <tr>
                                        <td>Napięcie [V]</td>
                                        <td>{product.napiecie || "-"}</td>
                                    </tr>
                                    {product.pradFazowy &&
                                        <tr>
                                            <td>Prąd fazowy [A]</td>
                                            <td>{product.pradFazowy}</td>
                                        </tr>
                                    }
                                    {product.maxTemperaturaOtoczenia &&
                                        <tr>
                                            <td>Max temp. otoczenia [&deg;C]</td>
                                            <td>{product.maxTemperaturaOtoczenia}</td>
                                        </tr>
                                    }
                                    {product.czestotliwosc &&
                                        <tr>
                                            <td>Częstotliwość [Hz]</td>
                                            <td>{product.czestotliwosc}</td>
                                        </tr>
                                    }
                                    {product.stopienOchrony &&
                                        <tr>
                                            <td>Stopien ochrony</td>
                                            <td>{product.stopienOchrony}</td>
                                        </tr>
                                    }
                                    </tbody>
                                </table>
                                <h4>Materiały</h4>
                                <table className="table">
                                    <tbody>
                                    <tr>
                                        <td>Korpus</td>
                                        <td>{product.korpus || "-"}</td>
                                    </tr>
                                    <tr>
                                        <td>Wirnik</td>
                                        <td>{product.wiernik || "-"}</td>
                                    </tr>
                                    <tr>
                                        <td>Wał</td>
                                        <td>{product.wal || "-"}</td>
                                    </tr>
                                    <tr>
                                        <td>Uszczelnienie mechaniczne</td>
                                        <td>{product.uszczelnienieMechaniczne || "-"}</td>
                                    </tr>
                                    </tbody>
                                </table>
                                <h4>Przyłącza</h4>
                                <table className="table">
                                    <tbody>
                                    <tr>
                                        <td>Ssący</td>
                                        <td>{product.przylaczaSsacy || "-"}</td>
                                    </tr>
                                    <tr>
                                        <td>Tłoczony</td>
                                        <td>{product.przylaczaTloczony || "-"}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        }
                        {activeTab === "options" &&
                            <div className="preview__details">
                                <h4>Dodatkowe opcje</h4>
                                <p>Układ sterowania:<br/>
                                    {product.ukladSterowania &&
                                    <span dangerouslySetInnerHTML={{
                                        __html: product.ukladSterowania,
                                    }}
                                    />
                                    }
                                    {!product.ukladSterowania && "-"}
                                </p>
                                <p>Specjalnie wykonane uszczelnienia:<br/>
                                    {product.specjalnieWykonaneUszczelnienia &&
                                    <span dangerouslySetInnerHTML={{
                                        __html: product.specjalnieWykonaneUszczelnienia,
                                    }}
                                    />
                                    }
                                    {!product.specjalnieWykonaneUszczelnienia && "-"}
                                </p>
                            </div>
                        }
                        {activeTab === "download" && product.pliki.length > 0 &&
                            <div className="preview__details">
                                <div className="files">
                                    {product.pliki.map((file) => (
                                        <div className="file-prev">
                                            <a href={file.url} target="_blank">
                                                <i className="icon-pdf"></i>
                                                <span>{file.filename}</span>
                                            </a>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        }
                        {product.powiazane && product.powiazane.length > 0 &&
                            <div>
                                <h3>Powiązane produkty:</h3>
                                <div className="more-products">
                                    {product.powiazane.map((item) => (
                                        <Link to={`/product/${item.slug}`} className="item">
                                            <div className="item__avatar">
                                                {item.gallery.length == 0 &&
                                                    <span className="icon-no-photo"></span>
                                                }
                                                {item.gallery.length > 0 && item.gallery.slice(0, 1).map(({ fluid: image }, idx) => (
                                                    <div key={idx}>
                                                        {image &&
                                                        <img src={image.src} alt="" />
                                                        }
                                                        {!image &&
                                                        <span className="icon-no-photo"></span>
                                                        }
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="item__description">
                                                <h5>{item.title}</h5>
                                            </div>
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </Layout>
        )
    }
}

export const query = graphql`
  query CategoryProductQuery($slug: String!) {
    product: datoCmsProduct(slug: { eq: $slug }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      slug
      description
      advantages
      category {
        title
        slug
      }
      subCategory {
        title
        slug
      }
      gallery {
        fluid(imgixParams: { auto: "compress" }) {
          ...GatsbyDatoCmsSizes
        }
      }
      cisnienie
      wydajnosc
      temperatura
      moc
      napiecie
      korpus
      wiernik
      wal
      uszczelnienieMechaniczne
      przylaczaSsacy
      przylaczaTloczony
      pradFazowy
      maxTemperaturaOtoczenia
      czestotliwosc
      stopienOchrony
      ukladSterowania
      specjalnieWykonaneUszczelnienia
      pliki {
        filename
        url
        title
      }
      powiazane {
        title
        slug
        gallery {
        fluid(imgixParams: { auto: "compress" }) {
          ...GatsbyDatoCmsSizes
        }
      }
      }
    }
  }
`